import React from 'react';
import ReactHtmlParser from 'html-react-parser';
import styled from 'styled-components';
import Gallery from 'ground-web/components/v1/GridGallery';
import SEO from '../../components/seo';
import Article from '../../components/article';
import PageTitle from '../../components/page-title';
import { decodeHtml } from '../../helpers';

const StyledArticle = styled(Article)`
  div:last-of-type {
    overflow: visible;
  }
`;

export default function Galleries(props) {
  const { pageContext } = props;
  const { title, content, images } = pageContext.gallery;

  if (!images) {
    return (
      <>
        <SEO title={decodeHtml(title)} />
        <StyledArticle>
          <PageTitle>{decodeHtml(title)}</PageTitle>
          <section>{ReactHtmlParser(decodeHtml(content))}</section>
        </StyledArticle>
      </>
    );
  }

  const parsed = images
    .filter((img) => img.imagem)
    .map((img) => ({ source: img.imagem.sourceUrl }));

  return (
    <>
      <SEO title={decodeHtml(title)} />
      <StyledArticle>
        <PageTitle>{decodeHtml(title)}</PageTitle>
        <section>{ReactHtmlParser(decodeHtml(content))}</section>
        <Gallery images={parsed} />
      </StyledArticle>
    </>
  );
}
